<template>
	<section>
		<!--顶部工具条-->
		<el-col :span="24">
			<el-form :inline="true">
				<el-form-item label="关键字:">
					<el-input v-model="filters.name" @input="(e) => (filters.name = utils.validForbid(e))" placeholder="请输入内容" @clear='clearContent' clearable ></el-input>
				</el-form-item>
				<!-- <el-form-item label="组织机构:" width>
					<el-cascader
						placeholder="请选择，支持搜索功能"
						style="width: 100%"
						v-model="OptionTreec"
						:options="options"
						:props="defaultProps"
						filterable
						change-on-select
					>
					</el-cascader>
				</el-form-item> -->
				<el-form-item label="启用标识:">
					<el-select v-model="filters.selectc">
						<el-option
							v-for="item in statusList"
							:key="item.value"
							:label="item.name"
							:value="item.value"
						>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
				</el-form-item>
			</el-form>
		</el-col>
		<!--列表-->
		<el-table
			:data="roles"
			border
			highlight-current-row
			v-loading="listLoading"
			@current-change="selectCurrentRow"
			style="width: 100%;"
		>
			<el-table-column type="index" label="序号" width="80" align="center" :index='indexMethod'></el-table-column>
            <el-table-column prop="name" label="角色名称" width align="center">
                <template slot-scope="scope">
                    <span class="bold-font" v-if="scope.row.isNotOperation">
                        {{scope.row.name}}
                    </span>
                    <span v-else>
                        {{scope.row.name}}
                    </span>
                </template>
            </el-table-column>
			<!-- <el-table-column prop="organizationInfoName" label="组织机构" width align="center"></el-table-column> -->
			<el-table-column prop="remarks" label="备注" width align="center"></el-table-column>
			<el-table-column prop="createTime" label="创建时间" :formatter="formatCreateTime" width align="center"></el-table-column>
			<el-table-column prop="isEnable" label="启用标识" width="200" align="center">
				<template slot-scope="scope">
					<el-tag :type="scope.row.isEnable  ? 'success' : 'danger'" disable-transitions>{{scope.row.isEnable ? "启用":"禁用"}}</el-tag>
				</template>
			</el-table-column>
		</el-table>
		<!--底部工具条-->
		<el-col :span="24" class="pageBar">
			<el-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="pages.pageIndex"
				:page-sizes="pages.pageArr"
				:page-size="pages.pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="pages.dataCount">
			</el-pagination>
		</el-col>
		<!--新增界面-->
		<el-dialog
			title="新增"
			:visible.sync="addFormVisible"
			v-model="addFormVisible"
			:close-on-click-modal="false"
		>
			<el-form :model="addForm" label-width="80px" :rules="addFormRules" ref="addForm">
				<el-form-item label="角色名:" prop="name">
					<el-input v-model="addForm.name" @keyup.enter.native="enterKey(1)" auto-complete="off"></el-input>
				</el-form-item>
				<!-- <el-form-item label="组织机构:" width>
					<el-cascader
						placeholder="请选择，支持搜索功能"
						style="width: 60%"
						v-model="OptionTree"
						:options="options"
						:props="defaultProps"
						filterable
						change-on-select
					>
					</el-cascader>
				</el-form-item> -->
				<el-form-item label="状态:" prop="isEnable">
					<el-select v-model="addForm.isEnable" placeholder="请选择角色状态">
						<el-option
						v-for="item in statusList"
						:key="item.value"
						:label="item.name"
						:value="item.value"
						>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="序号:">
					<el-input type="text" onkeyup="value=value.replace(/[^\d]/g,'')" @keyup.enter.native="enterKey(1)" v-model="addForm.sortNo" placeholder="请输入排列序号"></el-input>
				</el-form-item>
				<el-form-item label="备注:" prop="remarks">
					<el-input v-model="addForm.remarks" @keyup.enter.native="enterKey(1)" auto-complete="off"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click.native="addFormVisible = false">取消</el-button>
				<el-button type="primary" @click.native="addSubmit" :loading="addLoading">提交</el-button>
			</div>
		</el-dialog>
		<!--编辑界面-->
		<el-dialog
			title="编辑"
			:visible.sync="editFormVisible"
			v-model="editFormVisible"
			:close-on-click-modal="false"
		>
			<el-form :model="editForm" label-width="80px" :rules="editFormRules" ref="editForm">
				<el-form-item label="角色名:" prop="name">
					<el-input v-model="editForm.name" @keyup.enter.native="enterKey(2)" auto-complete="off"></el-input>
				</el-form-item>
				<!-- <el-form-item label="组织机构:">
					{{editForm.organizationInfoName}}
				</el-form-item> -->
				<el-form-item label="状态:" prop="isEnable">
					<el-select v-model="editForm.isEnable" placeholder="请选择角色状态">
						<el-option
						v-for="item in statusList"
						:key="item.value"
						:label="item.name"
						:value="item.value"
						>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="序号:">
					<el-input type="text" onkeyup="value=value.replace(/[^\d]/g,'')" @keyup.enter.native="enterKey(2)" v-model="editForm.sortNo" placeholder="请输入排列序号"></el-input>
				</el-form-item>
				<el-form-item label="备注:" prop="remarks">
					<el-input v-model="editForm.remarks" @keyup.enter.native="enterKey(2)" auto-complete="off"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click.native="editFormVisible = false">取消</el-button>
				<el-button type="primary" @click.native="editSubmit" :loading="editLoading">提交</el-button>
			</div>
		</el-dialog>
	</section>
</template>
<script>
import { getRoleListPage ,addRoleInfo,updateRoleInfo,deleteRoleInfo,getOrganizationTree } from "../../api/api";
import util from "../../../util/date";
import Toolbar from "../../components/Toolbar";
import { getButtonList } from "../../promissionRouter";
import Qs from 'qs'
export default {
    components: { Toolbar },
	data() {
		return {
			utils: '',
			pages: {  //关于分页的obj
				pageSize: 20,//默认每页条数
				pageArr: [10, 20, 30, 40],//默认每页条数选择
				pageIndex: 1, //默认进入页
				dataCount: 20, //默认总页数
			},
			filters: { //顶部筛选条件
				name: '',
				selectc: true
			},
			//是否启用状态
			statusList: [{  
				value: true,
				name: '启用'
			},{
				value: false,
				name: '禁用'
			}],
			roles: [],//角色信息列表
			currentRow: null,
			listLoading: false,
			buttonList: [],
			//新增界面数据
			addForm: {
				createUserID: "",
				name: "",
				isEnable: true,
				remarks:"",
				sortNo: 0,
			},
			OptionTree: '',
			OptionTreec: [],
			addFormRules: {
				name: [{ required: true, message: "请输入角色名", trigger: "blur" }],
				isEnable: [{ required: true, message: "请选择状态", trigger: "blur" }],
				// OptionTree: [{ required: true, message: '请选择组织机构', trigger: 'blur' }]
			},
			addFormVisible: false, //新增界面是否显示
			addLoading: false,
			//编辑界面数据
			editForm: {
				id: 0,
				name: "",
				isEnable: false,
				remarks:"",
				sortNo: '',
				organizationInfoName: '',
			},
			editFormVisible: false, //编辑界面是否显示
			editLoading: false,
			editFormRules: {
				name: [{ required: true, message: "请输入角色名", trigger: "blur" }],
				isEnable: [{ required: true, message: "请选择状态", trigger: "blur" }]
			},
			// 组织结构树数据
			defaultProps: {
				children: "children",
				label: "name",
				value:'id' //可要可不要
			},
			options: []
		};
	},
	methods: {
		// 清空筛选项，重新获取数据
		clearContent(){
			this.getData()
		},
		//
		selectCurrentRow(val) {
			this.currentRow = val;
		},
		//获取角色信息分页列表
		getData() {
			let _this = this;
			var params = {
				pageIndex: this.pages.pageIndex,
				pageSize: this.pages.pageSize,
				roleName: this.filters.name,
				isEnable: this.filters.selectc
			};    
			if(this.OptionTreec.length > 0){
				params.organizationInfoID = this.OptionTreec[this.OptionTreec.length-1];
			}
			this.listLoading = true;
			getRoleListPage(params).then(res => {
				this.roles = res.data.response.data;
				this.listLoading = false;
				this.pages.dataCount = res.data.response.dataCount
				this.pages.pageIndex = res.data.response.pageIndex
				if(res.data.response.pageSize > 0){
					this.pages.pageSize = res.data.response.pageSize
				}
			});
		},
		// 分页
		handleCurrentChange(val) {
			// if(this.filters.name != '' || this.filters.selectc != true){
			//   this.pages.pageIndex = 1
			// }else{
				this.pages.pageIndex = val;
			// }    
			this.getData();
		},
		handleSizeChange(val){
			this.pages.pageSize = val        
			this.getData();
		},
		callFunction(item) {
			this[item.func].apply(this, item);
		},
		//时间格式化
		formatCreateTime: function(row, column) {
			return !row.createTime || row.createTime == ""
			? ""
			: util.formatDate.format(new Date(row.createTime), "yyyy-MM-dd");
		},
		// 回车按钮
		enterKey(item) {
			if(item == 1){
				this.addSubmit();
			}else if(item == 2){
				this.editSubmit();
			}
		},
		//显示新增界面
		handleAdd() {
			this.addFormVisible = true;
			this.addForm = {
				createUserID: "",
				name: "",
				isEnable: true,
				remarks:"",
				sortNo: 0,
			};
		},
		// 查询
		getRoles(){
			this.pages.pageIndex = 1
			this.getData()
		},
		//新增
		addSubmit: function() {
			let _this = this;
			this.$refs.addForm.validate(valid => {
				if (valid) {
				// if(this.OptionTree.length > 0){}else{
				// 	this.$message({
				// 		message: '请选择【组织机构】一项',
				// 		type: 'warning'
				// 	});
				// 	return
				// }
				this.$confirm("确认提交吗？", "提示", {}).then(() => {
					this.addLoading = true;
					let para = Object.assign({}, this.addForm);
					var user = JSON.parse(window.localStorage.user);
					para.OrganizationInfoID = this.OptionTree[this.OptionTree.length-1]
					if (user && user.id > 0) {
					para.CreateUserID = user.id;
					} else {
						this.$message({
							message: "用户信息为空，先登录",
							type: "error"
						});
						_this.$router.replace(
							_this.$route.query.redirect ? _this.$route.query.redirect : "/"
						);
					}
					addRoleInfo(para).then(res => {
						if (util.isEmt.format(res)) {
							this.addLoading = false;
							return;
						}
						if (res.data.success) {
							this.addLoading = false;
							//NProgress.done();
							this.$message({
								message: res.data.message,
								type: "success"
							});
							this.$refs["addForm"].resetFields();
							this.addFormVisible = false;
							this.getData();
						} else {
							this.$message({
								message: res.data.message,
								type: "error"
							});
						}
					});
				});
				}
			});
		},
		//显示编辑界面
		handleEdit() {
			let row = this.currentRow;
			if (!row) {
				this.$message({
					message: "请选择要编辑的一行数据！",
					type: "error"
				});
				return;
			}
			this.editFormVisible = true;
			this.editForm = Object.assign({}, row);
		},
		//编辑
		editSubmit: function() {
			this.$refs.editForm.validate(valid => {
				if (valid) {
					this.$confirm("确认提交吗？", "提示", {}).then(() => {
						this.editLoading = true;
						let para = Object.assign({}, this.editForm);
						updateRoleInfo(para).then(res => {
							if (util.isEmt.format(res)) {
								this.editLoading = false;
								return;
							}
							if (res.data.success) {
								this.editLoading = false;
								//NProgress.done();
								this.$message({
									message: res.data.message,
									type: "success"
								});
								this.$refs["editForm"].resetFields();
								this.editFormVisible = false;
								this.getData();
							} else {
								this.$message({
									message: res.data.message,
									type: "error"
								});
							}
						});

					});
				}
			});
		},
		//删除
		handleDel() {
			let row = this.currentRow;
			if (!row) {
				this.$message({
					message: "请选择要删除的一行数据！",
					type: "error"
				});
				return;
			}
			this.$confirm("确认删除该记录吗?", "提示", {
				type: "warning"
			}).then(() => {
				this.listLoading = true;
				let params = { id: row.id };
				deleteRoleInfo(params).then(res => {
					if (util.isEmt.format(res)) {
						this.listLoading = false;
						return;
					}
					this.listLoading = false;
					if (res.data.success) {
						this.$message({
							message: "删除成功",
							type: "success"
						});
					} else {
						this.$message({
							message: res.data.message,
							type: "error"
						});
					}
					this.getData();
				});
			})
			.catch(() => {});
		},

		// 获取树结构数据
		getOrganizations() {
			var _this = this
			var ID = JSON.parse(localStorage.getItem("user")).ID
			var params = {
				parentId: 0
			}
			getOrganizationTree(params).then(res => {
				var data = res.data
				var resData = [data.response]
				if(data.success){
					if(data.response.name == "根节点"){
						_this.options = data.response.children
					}else{
						_this.options = resData
					}
				}
			})
		},
		// 表格数据分页条数累计
        indexMethod(index) {
            var currentPage = this.pages.pageIndex
            var pageSize = this.pages.pageSize
            return (index + 1) + (currentPage - 1) * pageSize
        },
	},
	mounted() {
		this.utils = util
		//首次加载角色信息列表
		this.getData();
		let routers = window.localStorage.router  ? JSON.parse(window.localStorage.router) : [];
		this.buttonList = getButtonList(this.$route.path, routers);
		this.getOrganizations();
	},
};
</script>
<style lang="stylus" scoped>
.rolesTop{
	width 100%;
	display flex;
}
.rolesInp{
	width 300px;
	margin-right 20px;
}
.pageBar{
  	margin-top 15px;
}
.bold-font {
    font-weight: bold;
}
</style>